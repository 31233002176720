<template>
  <section id="aboutme">
    <div class="container">
      <div class="row" :class="(data.bannerExist) ? 'pt-3' : 'padding-top-no-banner'">
        <div :class="data.json.responsive['img']">
          <img :src="`${publicPath}/assets/${data.portfolioName}/${data.portfolioName}.jpg`" :alt="data.json.img['alt']" :title="data.json.img['title']" class="img-fluid rounded">
        </div>
        <div :class="data.json.responsive['paragraph1']">
          <h1> {{ data.json['title'] }}</h1>
          <h4> {{ data.json['subtitle'] }}</h4>
          <p style="margin-top:-8px; font-size: 0.9em;">{{ data.json['qualification'] }}</p>
          <p v-html="data.json['paragraph1']"></p>
        </div>
        <div class="col-12 pt-3">
          <p v-html="data.json['paragraph2']"></p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'About',
  data () {
    return {
      publicPath: process.env.VUE_APP_BASE_URL
    }
  },
  computed: {
    data () {
      const cJson = this.$webData.components.find((e) => e.name === 'AboutMe');
      const portfolioName = this.$portfolioName;
      return {
        json: cJson,
        portfolioName: portfolioName,
        bannerExist: (this.$webData.components.find((e) => e.name === 'Banner') !== undefined)
      }
    }
  },
  mounted() {
    //console.log(this.bannerExist)
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .padding-top-no-banner {
    padding-top: 80px !important;
  }
</style>
